export const USER = 'users'
export const CURRENCY = 'currency'
export const COMPANY = 'company'
export const ITEM = 'item'
export const CUSTOMER = 'customer'
export const RESETPASSWORDFROMLINK = 'changePasswordFromEmail'
export const CHECKUNIQUEDISPLAYNAME = 'checkCustomerUnique'
export const VENDOR = 'vendor'
export const BANK = 'bank'
export const TRANSACTION = 'transaction'
export const INCOME = 'income'
export const EXPENSE = 'expense'
export const INVOICE = 'invoice'
export const PAYMENT = 'payment'