import axios from 'axios';
import { BANK, TRANSACTION } from '../../../constants/apiConstant';

// Action Types
export const FETCH_DATA_REQUEST = 'FETCH_DATA_REQUEST';
export const FETCH_DATA_SUCCESS = 'FETCH_DATA_SUCCESS';
export const FETCH_DATA_FAILURE = 'FETCH_DATA_FAILURE';

// Action Creators
export const fetchDataRequest = (key) => ({
  type: FETCH_DATA_REQUEST,
  payload: key,
});

export const fetchDataSuccess = (key, data) => ({
  type: FETCH_DATA_SUCCESS,
  payload: { key, data },
});

export const fetchDataFailure = (key, error) => ({
  type: FETCH_DATA_FAILURE,
  payload: { key, error },
});

// Thunk Actions

export const getTaxDeductionTypes = (taxType) => async (dispatch) => {
  const key = 'taxDeductionTypes';
  dispatch(fetchDataRequest(key));
  try {
    const res = await axios.get(`/tax/get-tax-deductions?taxType=${taxType}`);
    dispatch(fetchDataSuccess(key, res.data.payload?.result));
  } catch (error) {
    dispatch(fetchDataFailure(key, error.message));
  }
};

export const getStates = () => async (dispatch) => {
  const key = 'states';
  dispatch(fetchDataRequest(key));
  try {
    const res = await axios.get('/generale/get-state/61f77cdc30e70803c03b605e');
    dispatch(fetchDataSuccess(key, res.data.payload?.result));
  } catch (error) {
    dispatch(fetchDataFailure(key, error.message));
  }
};

export const getAccountType = () => async (dispatch) => {
  const key = 'accountTypes';
  dispatch(fetchDataRequest(key));
  try {
    const res = await axios.get('/income/get-account-types');
    const payloadData = res.data.payload?.result;
    const accountArr = payloadData.map((item) => ({
      label: item?.accountType,
      value: item?._id,
    }));
    dispatch(fetchDataSuccess(key, accountArr));
  } catch (error) {
    dispatch(fetchDataFailure(key, error.message));
  }
};


export const getPayThrough = () => async (dispatch) => {
  const key = 'payThrough';
  dispatch(fetchDataRequest(key));
  try {
    const res = await axios.post('/bank/get-all-banks');
    dispatch(fetchDataSuccess(key, res.data.payload?.result));
  } catch (error) {
    dispatch(fetchDataFailure(key, error.message));
  }
};

export const getAllVendors = () => async (dispatch) => {
  const key = 'vendors';
  dispatch(fetchDataRequest(key));
  try {
    const res = await axios.get('/expenses/get-all-vendors');
    dispatch(fetchDataSuccess(key, res.data.payload?.result));
  } catch (error) {
    dispatch(fetchDataFailure(key, error.message));
  }
};

export const getExpenseAmountOptions = () => async (dispatch) => {
  const key = 'expenseAmountOptions';
  dispatch(fetchDataRequest(key));
  try {
    const res = await axios.get('/expenses/get-accounts');
    dispatch(fetchDataSuccess(key, res.data.payload?.result));
  } catch (error) {
    dispatch(fetchDataFailure(key, error.message));
  }
};

export const getTaxList = () => async (dispatch) => {
  const key = 'taxList';
  dispatch(fetchDataRequest(key));
  try {
    const res = await axios.get('/tax/get-taxes');
    const TaxArr = [
      {
        value: 'add-new',
        label: 'Add Tax',
        type: 'button',
        color: 'flat-success',
      },
    ];
    if (res.data.payload?.result?.length > 0) {
      res.data.payload?.result.forEach((item) =>
        TaxArr.push({
          label: item?.name,
          value: item?._id,
          rate: item?.rate,
        })
      );
    }
    dispatch(fetchDataSuccess(key, TaxArr));
  } catch (error) {
    dispatch(fetchDataFailure(key, error.message));
  }
};

export const getTaxTypeList = () => async (dispatch) => {
  const key = 'taxTypeList';
  dispatch(fetchDataRequest(key));
  try {
    const res = await axios.get('/tax/get-tax-types');
    const TaxTypeArr = [];
    if (res.data.payload?.taxType?.length > 0) {
      res.data.payload?.taxType.forEach((item) =>
        TaxTypeArr.push({
          label: item?.name,
          value: item?._id,
        })
      );
    }
    dispatch(fetchDataSuccess(key, TaxTypeArr));
  } catch (error) {
    dispatch(fetchDataFailure(key, error.message));
  }
};

export const getCompanyCurrency = (cmpId) => async (dispatch) => {
  const key = 'companyCurrency';
  dispatch(fetchDataRequest(key));
  try {
    const res = await axios.get(`/company/get-company-currency/${cmpId}`);
    dispatch(fetchDataSuccess(key, res.data.payload.result.companyBasecurrency));
  } catch (error) {
    dispatch(fetchDataFailure(key, error.message));
  }
};

export const getAllCurrencies = () => async (dispatch) => {
  const key = 'currencies';
  dispatch(fetchDataRequest(key));
  try {
    const res = await axios.get(`/super-admin/get-active-currency`);
    const baseCurrencyOptions = res.data.payload.Currency.map((currencyObject) => ({
      label: currencyObject?.currencySymbol + ' ' + currencyObject?.currency,
      value: currencyObject?._id,
      currencySymbol: currencyObject?.currencySymbol,
      currency: currencyObject?.currency,
      identifier: currencyObject?.identifier,
    }));
    dispatch(fetchDataSuccess(key, baseCurrencyOptions));
  } catch (error) {
    dispatch(fetchDataFailure(key, error.message));
  }
};

export const getCustomer = (selectedCompanyId) => async (dispatch) => {
  const key = 'customers';
  dispatch(fetchDataRequest(key));
  const reqData = {
    companyId: selectedCompanyId,
  };
  try {
    const res = await axios.post('/customer/get-all-customer', reqData);
    const payloadData = res.data.payload?.result;
    let customerArr = [];
    if (payloadData?.length > 0) {
      payloadData.forEach((p) =>
        customerArr.push({
          label: p?.customerDisplayName,
          value: p?._id,
          otherDetail: { ...p },
        })
      );
    }
    dispatch(fetchDataSuccess(key, customerArr));
  } catch (error) {
    dispatch(fetchDataFailure(key, error.message));
  }
};

export const getPaymentMethods = () => async (dispatch) => {
  const key = 'paymentMethods';
  dispatch(fetchDataRequest(key));
  try {
    const res = await axios.get('/invoice/get-payment-method');
    const payloadData = res.data.payload?.result;
    const paymentArr = [
      {
        value: 'add-new',
        label: 'Add New Method',
        type: 'button',
        color: 'flat-success'
      },
      ...payloadData.map((item) => ({
        label: item?.name,
        value: item?._id
      }))
    ];
    dispatch(fetchDataSuccess(key, paymentArr));
  } catch (error) {
    dispatch(fetchDataFailure(key, error.message));
  }
};

export const getBankDetailById = (id) => async (dispatch) => {
  const key = 'bankDetailById';
  dispatch(fetchDataRequest(key));
  try {
    const res = await axios.get(`/${BANK}/get-bank/${id}`);
    const payloadData = res.data.payload?.result;
    dispatch(fetchDataSuccess(key, payloadData));
  } catch (error) {
    dispatch(fetchDataFailure(key, error.message));
  }
};


export const getTransactionById = (transactionId) => async (dispatch) => {
  const key = 'transactionById';
  dispatch(fetchDataRequest(key));
  try {
    const res = await axios.get(`/${TRANSACTION}/get-transaction-by-id/${transactionId}`);
    const payloadData = res.data.payload?.result;
    dispatch(fetchDataSuccess(key, payloadData));
  } catch (error) {
    dispatch(fetchDataFailure(key, error.message));
  }
};
