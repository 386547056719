import {
    FETCH_DATA_REQUEST,
    FETCH_DATA_SUCCESS,
    FETCH_DATA_FAILURE,
  } from '../../actions/APIs/apiActions';
  
  const initialState = {
    loading: {},
    data: {},
    error: {},
  };
  
  const apiReducers = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_DATA_REQUEST:
        return {
          ...state,
          loading: { ...state.loading, [action.payload]: true },
          error: { ...state.error, [action.payload]: null },
        };
      case FETCH_DATA_SUCCESS:
        return {
          ...state,
          loading: { ...state.loading, [action.payload.key]: false },
          data: { ...state.data, [action.payload.key]: action.payload.data },
        };
      case FETCH_DATA_FAILURE:
        return {
          ...state,
          loading: { ...state.loading, [action.payload.key]: false },
          error: { ...state.error, [action.payload.key]: action.payload.error },
        };
      default:
        return state;
    }
  };
  
  export default apiReducers;
  