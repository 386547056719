const initialState = {
  menuItems: [],
  accessRights: {}
}

const verticalMenuReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_MENU_ITEMS':
      return { ...state, menuItems: action.data }
    case 'SET_ACCESS_RIGHTS':
      return { ...state, accessRights: action.data }
    default:
      return state
  }
}

export default verticalMenuReducer
