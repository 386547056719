// ** Initial State
const initialState = {
  CreditNotes: [],
  CreditNoteDetail: {},
  totalPages: null,
  selectedCreditNote: null
}

const ViewCreditNoteReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_CREDITNOTES':
      return {
        ...state,
        CreditNotes: action.data,
        totalPages: action.params
      }
    case 'GET_CREDITNOTE_DETAIL':
      return {
        ...state,
        CreditNoteDetail: action.data
      }
    case 'SELECTED_CREDITNOTE':
      return { ...state, selectedCreditNote: action.id }
    default:
      return state
  }
}

export default ViewCreditNoteReducer
