// export const APP_BASE_URL = 'https://billing.nowonlinetech.in:3000/api/v1'
// export const APP_PORT = 'https://billing.nowonlinetech.in:3000'

// Staging
export const APP_BASE_URL = 'https://noamsstaging.nexuslinkdev.com:3021/api/v1'
export const APP_PORT = 'https://noamsstaging.nexuslinkdev.com:3021'

// Local
// export const APP_BASE_URL = 'http://localhost:8800/api/v1'
// export const APP_PORT = 'http://localhost:8800'
