import axios from 'axios'
import { APP_BASE_URL } from './setting'

axios.create({ baseURL: APP_BASE_URL })

let userData

try {
  userData = JSON.parse(localStorage.getItem('userData'))
} catch (error) {
  console.warn('something went wrong while parsing user data')
}

let token

if (userData && userData?.accessToken) {
  token = userData?.accessToken
}

axios.defaults.baseURL = APP_BASE_URL

if (token) {
  axios.defaults.headers.common = {
    Authorization: 'Bearer ' + token
  }
}

const shouldIntercept = (error) => {
  try {
    if (error.response.status == 409) {
      console.log('shouldIntercept', typeof error.response.status)
      localStorage.clear()
      window.location.reload()
    }
    return error.response.status === 401
  } catch (e) {
    return false
  }
}

function removeUserData() {
  localStorage.removeItem('userData')
  localStorage.removeItem('accessToken')
  // toast.error('Please login again', {
  //   autoClose: 2000, // Toast will close after 3 seconds
  //   onClose: () => {
  //     // Redirect to the login page after the toast is closed
  //     window.location.href = '/login'
  //   }
  // })
}

const setTokenData = (tokenData = {}) => {
  let user = localStorage.getItem('userData')
    ? JSON.parse(localStorage.getItem('userData'))
    : {}
  user.accessToken = tokenData.accessToken
  user.refreshToken = tokenData.refreshToken
  localStorage.setItem('accessToken', tokenData.accessToken)
  localStorage.setItem('userData', JSON.stringify(user))
}

const handleTokenRefresh = () => {
  let user = localStorage.getItem('userData')
    ? JSON.parse(localStorage.getItem('userData'))
    : false
  const refreshToken = user ? user?.refreshToken : ''

  return new Promise((resolve, reject) => {
    axios
      .post('/auth/refresh-tokens', {
        refreshToken: refreshToken
      })
      .then((response) => {
        const tokenData = {
          accessToken: response.data.payload?.result?.accessToken,
          refreshToken: response.data.payload?.result?.refreshToken
        }
        resolve(tokenData)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

const attachTokenToRequest = (request, token) => {
  request.headers['Authorization'] = 'Bearer ' + token
  axios.defaults.headers.common = {
    Authorization: 'Bearer ' + token,
    Accept: 'application/x-www-form-urlencoded'
  }
}

let customAxiosInterceptor = (axiosClient, customOptions = {}) => {
  let isRefreshing = false
  let failedQueue = []

  const options = {
    attachTokenToRequest,
    handleTokenRefresh,
    setTokenData,
    shouldIntercept,
    ...customOptions
  }
  const processQueue = (error, token = null) => {
    failedQueue.forEach((prom) => {
      if (error) {
        prom.reject(error)
      } else {
        prom.resolve(token)
      }
    })

    failedQueue = []
  }

  const interceptor = (error) => {
    if (error && error.config && error.config.url === '/auth/refresh-tokens') {
      removeUserData()
    }

    if (!options.shouldIntercept(error)) {
      return Promise.reject(error)
    }

    if (error.config._retry || error.config._queued) {
      return Promise.reject(error)
    }

    const originalRequest = error.config
    if (isRefreshing) {
      return new Promise(function (resolve, reject) {
        failedQueue.push({ resolve, reject })
      })
        .then((token) => {
          originalRequest._queued = true
          options.attachTokenToRequest(originalRequest, token)
          return axiosClient.request(originalRequest)
        })
        .catch((err) => {
          return Promise.reject(error) // Ignore refresh token request's "err" and return actual "error" for the original request
        })
    }

    originalRequest._retry = true
    isRefreshing = true
    return new Promise((resolve, reject) => {
      options.handleTokenRefresh
        .call(options.handleTokenRefresh)
        .then((tokenData) => {
          options.setTokenData(tokenData, axiosClient)
          options.attachTokenToRequest(originalRequest, tokenData.idToken)
          processQueue(null, tokenData.idToken)
          resolve(axiosClient.request(originalRequest))
        })
        .catch((err) => {
          processQueue(err, null)
          reject(err)
        })
        .finally(() => {
          isRefreshing = false
        })
    })
  }

  axiosClient.interceptors.response.use(undefined, interceptor)
}

customAxiosInterceptor(axios)

export default axios
