// ** Initial State
const initialState = {
  Invoices: [],
  InvoiceDetail: {},
  totalPages: null,
  selectedInvoice: null
}

const ViewExpenseReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_INVOICES':
      return {
        ...state,
        Invoices: action.data,
        totalPages: action.params
      }
    case 'GET_INVOICE_DETAIL':
      return {
        ...state,
        InvoiceDetail: action.data
      }
    case 'SELECTED_INVOICE':
      return { ...state, selectedInvoice: action.id }
    default:
      return state
  }
}

export default ViewExpenseReducer
